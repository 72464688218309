<template>

    <div class="bg center_xy">
            <div class="pic4-title" v-if="picShow == 3">
                <span>石家庄产业集群基本情况</span>
            </div>

        <!-- <div class="middle-map fl" v-if="picShow == 3">
            <div class="map-wrap">
                <h3 class="clearfix">
                    <dl class="fl">
                        <dt>生产企业</dt>
                        <dd class="unit-number">8,848</dd>
                    </dl>
                    <dl class="fl">
                        <dt>配套企业</dt>
                        <dd class="unit-number">2,768</dd>
                    </dl>
                    <dl class="fl">
                        <dt>从业人员</dt>
                        <dd class="unit-type">725,486</dd>
                    </dl>
                    <dl class="fl">
                        <dt>营业收入</dt>
                        <dd class="unit-wanyuan">49,968,865</dd>
                    </dl>
                    <dl class="fl">
                        <dt>上缴税金</dt>
                        <dd class="unit-wanyuan">1,995,032</dd>
                    </dl>
                </h3>
            </div>
        </div> -->

        <Index_city_com
                v-if="picShow == 1"
                class="city_box1"
                :title_in="title"
                :img_in="require('../assets/image/index/' + title + '.jpg')"
        ></Index_city_com>
        <div class="echarts">
            <div style="width: 100%; height: calc(100% - 10px)" ref="dotMap"></div>
        </div>
    </div>
</template>

<script>
    const echarts = require("echarts");
    const animationDelay_time = 500; //动画延时时间
    import drawMixin from "../utils/drawMixin";
    import Index_city_com from "../components/index_city_com";

    var d2data = {
        宁晋电缆: [114.91932, 37.6198],
        清河羊绒: [115.66718, 37.03993],
        魏县计量衡器: [114.93882, 36.35991],
        蔚县煤炭: [114.58897, 39.84072],
        沙河玻璃: [114.50332, 36.93516],
        武安钢铁: [114.20376, 36.69671],
        高阳纺织: [115.77884, 38.70007],
        蠡县皮毛: [115.6336, 38.48806],
        辛集制革: [115.21792, 37.94316],
        博野油漆与胶带: [115.43439, 38.42766],
        安平丝网与养猪: [115.51912, 38.23447],
        安国中药: [115.22664, 38.37845],
        任丘石油: [116.09954, 38.71164],
        河间汽配: [116.099, 38.44548],
        香河家具: [117.01645, 39.75142],
        大厂管件: [116.98951, 39.89665],
        迁安炼钢: [118.70073, 39.99836],
        遵化钢铁: [117.96567, 40.18924],
        徐水酿酒: [115.65586, 39.01865],
        容城服装: [115.861633, 39.042862],
        怀来葡萄酒: [115.51778, 40.41538],
        景县铁塔: [116.2706, 37.69234],
        饶阳蔬菜: [115.7256, 38.2353],
        望都辣椒加工: [115.20564, 38.739],
        涿州地毯: [115.97439, 39.48529],
        定州苗圃: [114.99025, 38.51626],
        涉县铁矿: [113.69065, 36.58544],
        冀州暖气输液器: [115.57938, 37.55085],
        藁城宫灯: [114.84676, 38.02166],
        南和宠物食品: [114.84676, 38.02166],
        隆尧方便面: [114.77031, 37.35025],
        昌黎葡萄酒: [119.16288, 39.71284],
        广宗童车: [115.28261, 37.07463],
        深泽布艺制品: [115.20094, 38.18417],
        武邑保险柜金属橱柜: [115.88751, 37.80186],
        故城压力容器: [115.96581, 37.34748],
        枣强皮毛玻璃钢: [115.78418, 37.45337],
        深州精品水果: [115.56001, 38.00022],
        高邑建材: [114.61148, 37.61493],
        武强乐器: [115.98253, 38.0412],
        曲阳石雕: [114.74501, 38.62231],
        宣化钻机: [115.09923, 40.60845],
        高碑店箱包: [115.97373, 39.32658],
        定兴礼帽: [115.7379, 39.22315],
        大城保温材料: [116.65423, 38.70469],
        高邑陶瓷: [114.61148, 37.61493],
        涞水红木家具: [115.71378, 39.39428],
        献县扣件: [116.12279, 38.19018],
        南皮五金: [116.76774, 37.9878],
        泊头除尘防爆模具: [116.57828, 38.08364],
        平乡自行车: [115.03007, 37.06319],
        永年标准件: [114.49095, 36.74771],
        临西轴承: [115.50104, 36.87082],
        文安机床与板材: [116.45848, 38.87328],
        东光塑料: [116.5371, 37.88828],
        磁县童装: [114.37392, 36.37396],
        唐县养殖业: [114.98295, 38.7479],
        满城石灰石: [115.32217, 38.94892],
        滦南制锹: [118.67414, 39.50394],
        新河钻机: [115.24214, 37.52867],
        任县密封件: [114.67132, 37.12092],
        盐山管道: [117.33025, 38.0583],
        孟村管件: [117.10418, 38.05341],
        雄县塑料制品: [116.108789, 38.994458],
        安新制鞋: [115.93564, 38.88535],
        固安渔具: [116.29852, 39.43825],
        灵寿石材: [114.38265, 38.30864],
        肃宁皮毛: [115.82997, 38.42277],
        张北牧业: [114.71987, 41.15862],
    };
    var d2 = [];
    var d3data1 = {
        东莞口岸: [113.741531, 23.019464],
        广州口岸: [113.2995, 23.367762],
        深圳口岸: [113.947441, 22.496193],
        佛山口岸: [113.118591, 23.011782],
        肇庆口岸: [112.480789, 23.045828],
        凭祥口岸: [106.75534, 22.10573],
        郑州口岸: [113.806168, 34.731621],
        绥芬河口岸: [131.192734, 44.41296],
        哈尔滨口岸: [126.669464, 45.740822],
        珲春口岸: [130.36577, 42.86249],
        图们口岸: [129.8439, 42.96805],
        集安口岸: [126.19269, 41.12622],
        丹东口岸: [124.393402, 40.121826],
        满洲里口岸: [117.389984, 49.629421],
        二连浩特口岸: [111.954231, 43.683842],
        阿拉山口口岸: [82.567993, 45.19212],
        河口口岸: [118.52544, 37.88624],
        北京口岸: [116.253517, 39.858189],
        上海口岸: [121.60611, 31.200806],
        霍尔果斯口岸: [80.405731, 44.212898],
    };
    var d3_1 = [];
    var d3data2 = {
        北京首都国际机场: [116.600388, 40.077332],
        北京大兴国际机场: [116.414207, 39.509991],
        上海虹桥国际机场: [121.339752, 31.196955],
        上海浦东国际机场: [121.808426, 31.150877],
        广州白云国际机场: [113.309402, 23.39032],
        武汉天河国际机场: [114.222443, 30.771749],
    };
    var d3_2 = [];
    // 港口
    var d3data3 = {
        舟山港: [122.048546, 29.878267],
        上海港: [121.505173, 31.250521],
        唐山港: [119.007904, 39.222412],
        广州港: [113.451942, 23.094193],
        青岛港: [120.20578, 36.017521],
        苏州港: [120.63132, 31.30227],
        天津港: [117.218689, 39.117302],
        日照港: [119.55574, 35.380772],
        烟台港: [121.39193, 37.555237],
        大连港: [121.661079, 38.938461],
    };
    var d3_3 = [];

    var d3data4 = {
        甘肃省: [103.71878, 36.10396],
        青海省: [101.76628, 36.6502],
        宁夏回族自治区: [106.24284, 38.47314],
        新疆维吾尔自治区: [88.31104, 43.36378],
        内蒙内蒙古自治区: [111.62299, 40.80772],
        陕西省: [108.93425, 34.23053],
    };
    var d3_4 = [];

    var d3data5 = {};
    var d3_5 = [];
    var d3_dian = [];

    var d4data = {
        东莞口岸: [113.741531, 23.019464],
        广州口岸: [113.2995, 23.367762],
        深圳口岸: [113.947441, 22.496193],
        佛山口岸: [113.118591, 23.011782],
        肇庆口岸: [112.480789, 23.045828],
        凭祥口岸: [106.75534, 22.10573],
        郑州口岸: [113.806168, 34.731621],
        绥芬河口岸: [131.192734, 44.41296],
        哈尔滨口岸: [126.669464, 45.740822],
        珲春口岸: [130.36577, 42.86249],
        图们口岸: [129.8439, 42.96805],
        集安口岸: [126.19269, 41.12622],
        丹东口岸: [124.393402, 40.121826],
        满洲里口岸: [117.389984, 49.629421],
        二连浩特口岸: [111.954231, 43.683842],
        阿拉山口口岸: [82.567993, 45.19212],
        河口口岸: [118.52544, 37.88624],
        北京口岸: [116.253517, 39.858189],
        上海口岸: [121.60611, 31.200806],
        霍尔果斯口岸: [80.405731, 44.212898],
        舟山港: [122.048546, 29.878267],
        上海港: [121.505173, 31.250521],
        唐山港: [119.007904, 39.222412],
        广州港: [113.451942, 23.094193],
        青岛港: [120.20578, 36.017521],
        苏州港: [120.63132, 31.30227],
        天津港: [117.218689, 39.117302],
        日照港: [119.55574, 35.380772],
        烟台港: [121.39193, 37.555237],
        大连港: [121.661079, 38.938461],
        北京首都国际机场: [116.600388, 40.077332],
        北京大兴国际机场: [116.414207, 39.509991],
        上海虹桥国际机场: [121.339752, 31.196955],
        上海浦东国际机场: [121.808426, 31.150877],
        广州白云国际机场: [113.309402, 23.39032],
        武汉天河国际机场: [114.222443, 30.771749],
        //国外点位
        俄罗斯莫斯科: [37.35, 55.45],
        白俄罗斯明斯克: [27.34, 53.54],
        乌兹别克斯坦塔什干: [69.18, 41.2],
        德国汉堡: [9.9599, 53.5459],
        纽约肯尼迪国际机场: [-73.778139, 40.641311],
        纽瓦克自由国际机场: [-74.17446, 40.689531],
        希思罗机场: [-0.45429, 51.470016],
        巴黎夏尔·戴高乐机场: [2.547925, 49.00969],
        法兰克福国际机场
    :
    [8.562152, 50.037933],
        布鲁塞尔机场
    :
    [4.481761, 50.898148],
        奥克兰国际机场
    :
    [4.481761, 50.898148],
        开普敦国际机场
    :
    [18.602085, -33.971463],
        堪培拉机场
    :
    [149.189586, -35.312934],
        多伦多皮尔逊国际机场
    :
    [-79.62482, 43.677718],
    }
    ;
    var d4 = [];
    var d4_dian = [];
    for (var d4_key in d4data) {
        d4.push({
            fromName: "石家庄",
            toName: d4_key,
            coords: [[114.53952, 38.03647], d4data[d4_key]],
        });
        d4_dian.push({
            name: d4_key,
            value: d4data[d4_key],
            symbolSize: 10,
            itemStyle: {
                normal: {
                    color: "#fff",
                    shadowBlur: 10, //发光图形阴影的模糊大小
                    shadowColor: "#0000FF", //阴影颜色
                },
            },
        });
    }
    var type = ["第一个图", "第2个图", "第3个图", "第4个图"]; //横坐标
    var chinaJson = require("../assets/map/china.json"); //地图json
    var hebeiJson = require("../assets/map/hebei.json"); //地图json
    var worldJson = require("../assets/map/world.json"); //地图json
    var ouyaJson = require("../assets/map/ouya.json"); //地图json
    var sjzJson = require("../assets/map/shijiazhuang.json"); //地图json
    export default {
        name: "dotMap",
        mixins: [drawMixin],
        components: {Index_city_com},
        data() {
            return {
                myCharts: null,
                picShow: false,
                title: "宁晋电缆",
            };
        },
        mounted() {
            for (var key in d2data) {
                d2.push({
                    name: key,
                    value: d2data[key],
                    symbolSize: 10,
                });
            }
            for (var d3_key_1 in d3data1) {
                d3_1.push({
                    fromName: "石家庄",
                    toName: d3_key_1,
                    coords: [[114.53952, 38.03647], d3data1[d3_key_1]],
                });
                d3_dian.push({
                    name: d3_key_1,
                    value: d3data1[d3_key_1],
                    symbolSize: 10,
                    itemStyle: {normal: {color: "#e45f56"}},
                });
            }
            for (var d3_key_2 in d3data2) {
                d3_2.push({
                    fromName: "石家庄",
                    toName: d3_key_2,
                    coords: [[114.53952, 38.03647], d3data2[d3_key_2]],
                });
                d3_dian.push({
                    name: d3_key_2,
                    value: d3data2[d3_key_2],
                    symbolSize: 10,
                    itemStyle: {normal: {color: "#a3d39c"}},
                });
            }
            for (var d3_key_3 in d3data3) {
                d3_3.push({
                    fromName: "石家庄",
                    toName: d3_key_3,
                    coords: [[114.53952, 38.03647], d3data3[d3_key_3]],
                });
                d3_dian.push({
                    name: d3_key_3,
                    value: d3data3[d3_key_3],
                    symbolSize: 10,
                    itemStyle: {normal: {color: "#7accc8"}},
                });
            }
            for (var d3_key_4 in d3data4) {
                d3_4.push({
                    fromName: "石家庄",
                    toName: d3_key_4,
                    coords: [[114.53952, 38.03647], d3data4[d3_key_4]],
                });
                d3_dian.push({
                    name: d3_key_4,
                    value: d3data4[d3_key_4],
                    symbolSize: 10,
                    itemStyle: {normal: {color: "#4aaaa5"}},
                });
            }
            for (var d3_key_5 in d3data5) {
                d3_5.push({
                    fromName: "石家庄",
                    toName: d3_key_5,
                    coords: [[114.53952, 38.03647], d3data5[d3_key_5]],
                });
                d3_dian.push({
                    name: d3_key_5,
                    value: d3data5[d3_key_5],
                    symbolSize: 10,
                    itemStyle: {normal: {color: "#669966"}},
                });
            }

            // 初始化echarts
            this.myChart = echarts.init(this.$refs.dotMap, null,{devicePixelRatio: 2.5});
            echarts.registerMap("china", chinaJson);
            echarts.registerMap("hebei", hebeiJson);
            echarts.registerMap("world", worldJson);
            echarts.registerMap("ouya", ouyaJson);
            echarts.registerMap("sjz", sjzJson);

            // echarts默认结构
            var optionXyMap01 = {
                // 下边线段选择器的样式

                baseOption: {
                    animation: true,
                    animationDuration: 1000,
                    animationEasing: "cubicInOut",
                    animationDurationUpdate: 1000,
                    animationEasingUpdate: "cubicInOut",
                    timeline: {
                        data: type,
                        axisType: "category",
                        autoPlay: false,
                        playInterval: 3000,
                        replaceMerge: ["series", "geo"],
                        left: "10%",
                        right: "10%",
                        bottom: "3%",
                        width: "80%",
                        label: {
                            normal: {
                                textStyle: {
                                    color: "#ddd",
                                },
                            },
                            emphasis: {
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                        },
                        symbolSize: 20,
                        lineStyle: {
                            color: "#555",
                        },
                        checkpointStyle: {
                            // 选中后的样式
                            borderColor: "#777",
                            borderWidth: 0,
                            color: "#013954",
                        },
                        controlStyle: {
                            showNextBtn: true,
                            showPrevBtn: true,
                            normal: {
                                color: "#666",
                                borderColor: "#666",
                            },
                            emphasis: {
                                color: "#aaa",
                                borderColor: "#aaa",
                            },
                        },
                    },
                },
                options: [],
            };
            //第一张图
            optionXyMap01.options.push({
                backgroundColor: "transparent",
                tooltip: {
                    trigger: "item",
                },
                formatter: "{a}<br />{b}",
                // 地图配置
                geo: {
                    show: true,
                    map: "hebei",
                    roam: true,
                    zoom: 1,
                    // center: [102.13531246, 34.0267395887],
                    label: {
                        normal: {
                            show: true,
                            color: "rgb(249, 249, 249)", //省份标签字体颜色
                            formatter: (p) => {
                                switch (p.name) {
                                    case "内蒙古自治区":
                                        p.name = "内蒙古";
                                        break;
                                    case "西藏自治区":
                                        p.name = "西藏";
                                        break;
                                    case "新疆维吾尔自治区":
                                        p.name = "新疆";
                                        break;
                                    case "宁夏回族自治区":
                                        p.name = "宁夏";
                                        break;
                                    case "广西壮族自治区":
                                        p.name = "广西";
                                        break;
                                    case "香港特别行政区":
                                        p.name = "香港";
                                        break;
                                    case "澳门特别行政区":
                                        p.name = "澳门";
                                        break;
                                    default:
                                        break;
                                }
                                return p.name;
                            },
                        },
                        emphasis: {
                            show: true,
                            color: "rgb(249, 249, 249)", //省份标签字体颜色
                        },
                    },
                    itemStyle: {
                        normal: {
                            borderColor: "rgba(147, 235, 248, 1)",
                            borderWidth: 1,
                            areaColor: {
                                type: "radial",
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                                    },
                                ],
                                globalCoord: false, // 缺省为 false
                            },
                            shadowColor: "rgba(128, 217, 248, 1)",
                            // shadowColor: 'rgba(255, 255, 255, 1)',
                            shadowOffsetX: -2,
                            shadowOffsetY: 2,
                            shadowBlur: 10,
                        },
                        emphasis: {
                            areaColor: "#389BB7",
                            borderWidth: 0,
                        },
                    },
                },
                series: [
                    //石家庄市主导产业
                    {
                        name: "石家庄市主导产业",
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        data: [
                            {
                                name: "医药",
                                value: [114.18, 38.31],
                                symbolSize: 25,
                            },
                            {
                                name: "高端制造",
                                value: [114.52, 37.98],
                                symbolSize: 25,
                            },
                            {
                                name: "电子信息",
                                value: [115.24, 38.02],
                                symbolSize: 25,
                            },
                        ],
                        showEffectOn: "render",
                        rippleEffect: {
                            brushType: "stroke",
                        },
                        hoverAnimation: true,
                        itemStyle: {
                            normal: {
                                color: "#de4307",
                                shadowBlur: 10,
                                shadowColor: "#de4307",
                            },
                        },
                        zlevel: 1,
                    },
                    //河北省钢铁产业
                    {
                        name: "河北省主导产业",
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        data: [
                            {
                                name: "钢铁",
                                value: [118.46023, 39.27313],
                                symbolSize: 40,
                            },
                            {
                                name: "钢铁",
                                value: [114.49339, 36.61853],
                                symbolSize: 35,
                            },
                            {
                                name: "钢铁",
                                value: [114.53952, 114.53952],
                                symbolSize: 30,
                            },
                            {
                                name: "钢铁",
                                value: [116.68572, 39.50311],
                                symbolSize: 28,
                            },
                            {
                                name: "钢铁",
                                value: [119.48458, 39.83507],
                                symbolSize: 25,
                            },
                        ],
                        showEffectOn: "render",
                        rippleEffect: {
                            brushType: "stroke",
                        },
                        hoverAnimation: true,
                        itemStyle: {
                            normal: {
                                color: "#f29c2b",
                                shadowBlur: 10,
                                shadowColor: "#f29c2b",
                            },
                        },
                        zlevel: 1,
                    },
                    //河北省油田产业
                    {
                        name: "河北省主导产业",
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        data: [
                            {
                                name: "油田",
                                value: [116.86638, 38.31404],
                                symbolSize: 35,
                            },
                            {
                                name: "油田",
                                value: [118.2, 39.68],
                                symbolSize: 32,
                            },
                        ],
                        showEffectOn: "render",
                        rippleEffect: {
                            brushType: "stroke",
                        },
                        hoverAnimation: true,
                        itemStyle: {
                            normal: {
                                color: "#f6d04d",
                                shadowBlur: 10,
                                shadowColor: "#f6d04d",
                            },
                        },
                        zlevel: 1,
                    },
                    //河北省食品制造产业
                    {
                        name: "河北省主导产业",
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        data: [
                            {
                                name: "食品制造",
                                value: [116.75, 39.25],
                                symbolSize: 38,
                            },
                            {
                                name: "食品制造",
                                value: [114.56, 38.45],
                                symbolSize: 35,
                            },
                            {
                                name: "食品制造",
                                value: [117.80024, 40.95913],
                                symbolSize: 30,
                            },
                        ],
                        showEffectOn: "render",
                        rippleEffect: {
                            brushType: "stroke",
                        },
                        hoverAnimation: true,
                        itemStyle: {
                            normal: {
                                color: "#8bc24c",
                                shadowBlur: 10,
                                shadowColor: "#8bc24c",
                            },
                        },
                        zlevel: 1,
                    },
                ],
            });
            //第二张图
            optionXyMap01.options.push({
                backgroundColor: "transparent",
                tooltip: {
                    trigger: "item",
                },
                formatter: "{b}",
                // 地图配置
                geo: {
                    show: true,
                    map: "hebei",
                    roam: true,
                    zoom: 1,
                    center: [121.0, 39.1],
                    label: {
                        normal: {
                            show: true,
                            color: "rgb(249, 249, 249)", //省份标签字体颜色
                            formatter: (p) => {
                                switch (p.name) {
                                    case "内蒙古自治区":
                                        p.name = "内蒙古";
                                        break;
                                    case "西藏自治区":
                                        p.name = "西藏";
                                        break;
                                    case "新疆维吾尔自治区":
                                        p.name = "新疆";
                                        break;
                                    case "宁夏回族自治区":
                                        p.name = "宁夏";
                                        break;
                                    case "广西壮族自治区":
                                        p.name = "广西";
                                        break;
                                    case "香港特别行政区":
                                        p.name = "香港";
                                        break;
                                    case "澳门特别行政区":
                                        p.name = "澳门";
                                        break;
                                    default:
                                        break;
                                }
                                return "{title|}" + " " + p.name;
                            },
                            rich: {
                                a: {
                                    backgroundColor: "red",
                                    height: 12,
                                    width: 12,
                                    borderRadius: 6,
                                },
                            },
                        },
                        emphasis: {
                            show: true,
                            color: "rgb(249, 249, 249)", //省份标签字体颜色
                        },
                    },
                    itemStyle: {
                        normal: {
                            borderColor: "rgba(147, 235, 248, 1)",
                            borderWidth: 1,
                            areaColor: {
                                type: "radial",
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                                    },
                                ],
                                globalCoord: false, // 缺省为 false
                            },
                            shadowColor: "rgba(128, 217, 248, 1)",
                            // shadowColor: 'rgba(255, 255, 255, 1)',
                            shadowOffsetX: -2,
                            shadowOffsetY: 2,
                            shadowBlur: 10,
                        },
                        emphasis: {
                            areaColor: "#389BB7",
                            borderWidth: 0,
                        },
                    },
                },
                series: [
                    {
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        data: d2,
                        showEffectOn: "render",
                        labelLine: {
                            show: false,
                        },
                        labelLayout: function () {
                            return {
                                x: 100,
                                moveOverlap: 100,
                            };
                        },
                        label: {
                            // 在文本中，可以对部分文本采用 rich 中定义样式。
                            // 这里需要在文本中使用标记符号：
                            // `{styleName|text content text content}` 标记样式名。
                            // 注意，换行仍是使用 '\n'。
                            show: false,
                            formatter: [
                                "{title|这段文本采用样式a}",
                                "{subTitle|这段文本采用样式b}这段用默认样式{x|这段用样式x}",
                            ].join("\n"),

                            rich: {
                                a: {
                                    color: "red",
                                    lineHeight: 10,
                                },
                                b: {
                                    backgroundColor: {
                                        image: "xxx/xxx.jpg",
                                    },
                                    height: 40,
                                },
                                x: {
                                    fontSize: 18,
                                    fontFamily: "Microsoft YaHei",
                                    borderColor: "#449933",
                                    borderRadius: 4,
                                },
                            },
                        },
                        rippleEffect: {
                            brushType: "stroke",
                        },
                        hoverAnimation: true,
                        itemStyle: {
                            normal: {
                                color: "#f6d04d",
                                shadowBlur: 10,
                                shadowColor: "#f6d04d",
                            },
                        },
                        zlevel: 1,
                    },
                ],
            });
            //第三张图
            optionXyMap01.options.push({
                backgroundColor: "transparent",
                tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                        console.log(params);
                        if (params.seriesType == "effectScatter") {
                            return params.data.name;
                        } else if (params.seriesType == "lines") {
                            return params.data.toName;
                        } else {
                            return params.name;
                        }
                    },
                },
                formatter: "{b}",
                // 地图配置
                geo: {
                    show: true,
                    map: "world",
                    roam: true,
                    zoom: 1,
                    // center: [102.13531246, 34.0267395887],
                    label: {
                        normal: {
                            show: false,
                            color: "rgb(249, 249, 249)", //省份标签字体颜色
                            formatter: (p) => {
                                switch (p.name) {
                                    case "中国":
                                        p.name = "中国";
                                        break;
                                }
                                return p.name;
                            },
                        },
                        emphasis: {
                            show: false,
                        },
                    },
                    itemStyle: {
                        normal: {
                            borderColor: "rgba(147, 235, 248, 1)",
                            borderWidth: 1,
                            areaColor: {
                                type: "radial",
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                                    },
                                ],
                                globalCoord: false, // 缺省为 false
                            },
                            shadowColor: "rgba(128, 217, 248, 1)",
                            // shadowColor: 'rgba(255, 255, 255, 1)',
                            shadowOffsetX: -2,
                            shadowOffsetY: 2,
                            shadowBlur: 10,
                        },
                        emphasis: {
                            areaColor: "#389BB7",
                            borderWidth: 0,
                        },
                    },
                },
                series: [
                    {
                        // name: '终点',
                        type: "scatter",

                        coordinateSystem: "geo",
                        data: d4_dian,
                        zlevel: 3,
                        encode: {
                            value: 2,
                        },
                        animationDelay: function (idx) {
                            return idx * animationDelay_time;
                        },
                        label: {
                            formatter: "{b}",
                            position: "right",
                            show: true,
                        },
                        emphasis: {
                            label: {
                                show: true,
                            },
                        },
                    },
                    {
                        // name: "起点",
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        zlevel: 6,
                        rippleEffect: {
                            //涟漪特效
                            brushType: "stroke", //波纹绘制方式 stroke，fill
                            period: 10, //动画时间，值越小速度越快
                            scale: 6, //波纹圆环最大显示，值越大波纹越大
                        },
                        label: {
                            emphasis: {
                                show: true,
                                position: "right",
                                formatter: "{b}",
                            },
                        },
                        symbolSize: 2,
                        showEffectOn: "render",
                        itemStyle: {
                            normal: {
                                color: "#46bee9",
                            },
                        },

                        data: [
                            {
                                name: "石家庄",
                                value: [114.53952, 38.03647],
                                symbolSize: 50,
                                itemStyle: {
                                    normal: {
                                        color: "#fff",
                                        shadowBlur: 10, //发光图形阴影的模糊大小
                                        shadowColor: "#0000FF", //阴影颜色
                                    },
                                },
                            },
                        ],
                    },
                    {
                        // name: "线路",
                        type: "lines",
                        coordinateSystem: "geo",
                        zlevel: 2,
                        large: true,
                        effect: {
                            show: true,
                            constantSpeed: 60,
                            period: 10, //箭头指向速度，值越小速度越快
                            symbol: "arrow", //箭头图标，pin 圆点
                            symbolSize: 7, //图标大小
                            trailLength: 0.01, //特效尾迹长度[0,1]值越大，尾迹越长重
                            delay: function (idx) {
                                return idx * animationDelay_time;
                            },
                        },
                        lineStyle: {
                            normal: {
                                color: "#fff",
                                shadowBlur: 10, //发光图形阴影的模糊大小
                                shadowColor: "#0000FF", //阴影颜色
                                width: 1, //尾迹线条宽度
                                opacity: 0.8, //尾迹线条透明度
                                curveness: 0.2, //尾迹线条曲直度
                            },
                        },
                        data: d4,
                        animationDelay: function (idx) {
                            return idx * animationDelay_time;
                        },
                    },
                ],
            });
            //第四张图
            optionXyMap01.options.push({
                backgroundColor: "transparent",
                tooltip: {
                    trigger: "item",
                },
                formatter: "{a}<br />{b}",
                // 地图配置
                geo: {
                    show: true,
                    map: "sjz",
                    roam: true,
                    zoom: 0.8,
                    center: [114.618654, 37.99],
                    label: {
                        normal: {
                            show: true,
                            color: "rgb(249, 249, 249)", //省份标签字体颜色
                            formatter: (p) => {
                                switch (p.name) {
                                    case "新华区":
                                        p.name = '';
                                        break;
                                    case "桥西区":
                                        p.name = '';
                                        break;
                                    case "长安区":
                                        p.name = "石家庄市（3）";
                                        break;
                                    case "裕华区":
                                        p.name = '';
                                        break;
                                    case "平山县":
                                        p.name = '平山县（2）';
                                        break;
                                    case "井陉矿区":
                                        p.name = '井陉矿区（1）';
                                        break;
                                    case "井陉县":
                                        p.name = '井陉县（1）';
                                        break;
                                    case "元氏县":
                                        p.name = '元氏县（3）';
                                        break;
                                    case "高邑县":
                                        p.name = '高邑县（3）';
                                        break;
                                    case "赵县":
                                        p.name = '赵县（2）';
                                        break;
                                    case "栾城区":
                                        p.name = '栾城区（6）';
                                        break;
                                    case "藁城区":
                                        p.name = '藁城区（7）';
                                        break;
                                    case "晋州市":
                                        p.name = '晋州市（3）';
                                        break;
                                    case "深泽县":
                                        p.name = '深泽县（1）';
                                        break;
                                    case "无极县":
                                        p.name = '无极县（2）';
                                        break;
                                    case "正定县":
                                        p.name = '正定县（4）';
                                        break;
                                    case "新乐市":
                                        p.name = '新乐市（4）';
                                        break;
                                    case "行唐县":
                                        p.name = '行唐县（1）';
                                        break;
                                    case "灵寿县":
                                        p.name = '灵寿县（1）';
                                        break;
                                }
                                return p.name;
                            },
                        },
                        emphasis: {
                            show: true,
                            color: "rgb(249, 249, 249)", //省份标签字体颜色
                        },
                    },
                    itemStyle: {
                        normal: {
                            borderColor: "rgba(147, 235, 248, 1)",
                            borderWidth: 1,
                            areaColor: {
                                type: "radial",
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                                    },
                                ],
                                globalCoord: false, // 缺省为 false
                            },
                            shadowColor: "rgba(128, 217, 248, 1)",
                            // shadowColor: 'rgba(255, 255, 255, 1)',
                            shadowOffsetX: -2,
                            shadowOffsetY: 2,
                            shadowBlur: 10,
                        },
                        emphasis: {
                            areaColor: "#389BB7",
                            borderWidth: 0,
                        },
                    },
                },
                series: [
                    {
                        name: "石家庄市产业集群",
                        type: "scatter",
                        coordinateSystem: "geo",
                        labelLine: {
                            show: true,
                            length2: 10,
                        },
                        labelLayout: function (params) {
                            let x = 0;
                            let y = 0;
                            debugger;// 间隔140/160
                            switch (params.dataIndex) {
                                case 0: //灵寿县
                                    x = 1080;
                                    y = 160;
                                    break;
                                case 1: //平山县
                                    x = 250;
                                    y = 210;
                                    break;
                                case 2: //井陉县
                                    x = 50;
                                    y = 340;
                                    break;
                                case 3: //石家庄市
                                    x = 50;
                                    y = 640;
                                    break;
                                case 4: //元氏县
                                    x = 50;
                                    y = 780;
                                    break;
                                case 5: //高邑县
                                    x = 580;
                                    y = 780;
                                    break;
                                case 6: //正定县
                                    x = 50;
                                    y = 480;
                                    break;
                                case 7: //赵县
                                    x = 1300;
                                    y = 640;
                                    break;
                                case 8: //无极县
                                    x = 1400;
                                    y = 360;
                                    break;
                                case 9: //深泽县
                                    x = 1270;
                                    y = 300;
                                    break;
                                case 10: //新乐市
                                    x = 1280;
                                    y = 180;
                                    break;
                                case 11: //栾城区
                                    x = 1040;
                                    y = 780;
                                    break;
                                case 12://藁城区
                                    x = 1320;
                                    y = 380;
                                    break;
                            }
                            return {
                                x,
                                y,
                            };
                        },

                        label: {
                            normal: {
                                show: true,
                                symbolSize: 1,
                                // backgroundColor: "rgba(204, 204, 204, 0.1)",
                                // borderColor: '#74D0E5',
                                // borderWidth: 0.3,
                                // borderRadius: 4,
                                // padding: [10, 0, 10, 10],
                                // opacity: 1,
                                rich: {
                                    title: {
                                        color: "#74D0E5",
                                        lineHeight: 18,
                                        height: 18,
                                        fontWeight: "bolder",
                                        fontSize: 12,
                                        opacity: 1,
                                    },
                                    subTitle: {
                                        color: "#74D0E5",
                                        height: 15,
                                        opacity: 1,
                                        fontSize: 9,
                                    },
                                    content: {
                                        color: "white",
                                        height: 15,
                                        opacity: 1,
                                        fontSize: 8,
                                    },
                                },
                            },
                        },

                        data: [
                            {
                                name: "灵寿县",
                                value: [114.316464, 38.312591],
                                label: {
                                    formatter: [
                                        "{title|灵寿县石材产业集群}",
                                        "{subTitle|（石材制品）}",
                                        "{content|生产企业1671个}",
                                        "{content|配套企业45个}",
                                        "{content|从业人员49397人}",
                                        "{content|营业收入419942万元}",
                                        "{content|上缴税金15756万元}",
                                        "{content|国内市场占有率31%}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 130,
                                        },
                                    },
                                },
                            },
                            {
                                name: "平山县",
                                value: [113.847611, 38.271116],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|平山县石材产业集群}{title|平山县冶金产业集群}",
                                        "{subTitle|（石材板材）}{subTitle|（螺纹钢中厚板）}",
                                        "{content|生产企业78个}{content|生产企业6个}",
                                        "{content|配套企业15个}{content|配套企业2个}",
                                        "{content|从业人员2461人}{content|从业人员26576人}",
                                        "{content|营业收入98524万元}{content|营业收入6267390万元}",
                                        "{content|上缴税金1827万元}{content|上缴税金4579万元}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 130,
                                        },
                                        subTitle: {
                                            width: 130,
                                        },
                                        content: {
                                            width: 130,
                                        },
                                    },
                                },
                            },
                            {
                                name: "井陉县",
                                value: [114.081144, 38.134208],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|井陉矿区煤炭冶金产业集群}{padding_fill|}{title|井陉县钙镁产业集群}",
                                        "{subTitle||煤炭焦化|}{padding_fill|}{subTitle||轻质碳酸钙、硫酸镁|}",
                                        "{content|生产企业27个}{padding_fill|}{content|生产企业61个}",
                                        "{content|配套企业6个}{padding_fill|}{content|配套企业45个}",
                                        "{content|从业人员3474人}{padding_fill|}{content|从业人员7856人}",
                                        "{content|营业收入874462万元}{padding_fill|}{content|营业收入144789万元}",
                                        "{content|上缴税金11793万元}{padding_fill|}{content|上缴税金4345万元}",
                                        "{content|              }{padding_fill|}{content|国内市场占有率25%}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 110,
                                        },
                                        subTitle: {
                                            width: 110,
                                        },
                                        content: {
                                            width: 110,
                                        },
                                        padding_fill: {
                                            width: 50,
                                        }
                                    },
                                },
                            },
                            {
                                name: "石家庄市",
                                value: [114.517554, 38.046358],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|石家庄市高新区生物医药产业集群}{padding_fill|}{title|石家庄市循环化产业集群}{title|石家庄市高新区先进设备制造产业集群}",
                                        "{subTitle|（维C、通心络、连药清瘟）}{padding_fill|}{subTitle|（化工产品）}{subTitle|（金刚石锯片、列车空调）}",
                                        "{content|生产企业65个}{padding_fill|}{content|生产企业30个}{content|生产企业129个}",
                                        "{content|配套企业10个}{padding_fill|}{content|配套企业26个}{content|配套企业25个}",
                                        "{content|从业人员33057人}{padding_fill|}{content|从业人员23000人}{content|从业人员28511人}",
                                        "{content|营业收入385654万元}{padding_fill|}{content|营业收入3068580万元}{content|营业收入7140125万元}",
                                        "{content|上缴税金234778万元}{padding_fill|}{content|上缴税金808946万元}{content|上缴税金292761万元}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 150,
                                        },
                                        subTitle: {
                                            width: 150,
                                        },
                                        content: {
                                            width: 150,
                                        },
                                        padding_fill: {
                                            width: 50,
                                        }
                                    },
                                },
                            },
                            {
                                name: "元氏县",
                                value: [114.52452, 37.770286],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|元氏县环保炉具产业集群}{title|元氏县精细化工产业集群}{title|元氏县装备制造产业集群}",
                                        "{subTitle|（民用节能采暖炉）}{subTitle|（精细化工、医药、原药）}{subTitle|（机械制造）}",
                                        "{content|生产企业7个}{content|生产企业61个}{content|生产企业20个}",
                                        "{content|配套企业5个}{content|配套企业6个}{content|配套企业2个}",
                                        "{content|从业人员3920人}{content|从业人员6720人}{content|从业人员2326人}",
                                        "{content|营业收入145063万元}{content|营业收入2024362万元}{content|营业收入1215700万元}",
                                        "{content|上缴税金1543万元}{content|上缴税金839652万元}{content|上缴税金24900万元}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 150,
                                        },
                                        subTitle: {
                                            width: 150,
                                        },
                                        content: {
                                            width: 150,
                                        },
                                    },
                                },
                            },
                            {
                                name: "高邑县",
                                value: [114.622148, 37.618908],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|高邑县纺织产业集群}{title|高邑县建陶产业集群}{title|高邑县锌品产业集群}",
                                        "{subTitle|（棉纱）}{subTitle|（高档地板砖）}{subTitle|（氧化锌）}",
                                        "{content|生产企业120个}{content|生产企业25个}{content|生产企业25个}",
                                        "{content|配套企业9个}{content|配套企业16个}{content|配套企业20个}",
                                        "{content|从业人员8000人}{content|从业人员25000人}{content|从业人员4500人}",
                                        "{content|营业收入429000万元}{content|营业收入600000万元}{content|营业收入280000万元}",
                                        "{content|上缴税金14100万元}{content|上缴税金10000万元}{content|上缴税金22200万元}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 130,
                                        },
                                        subTitle: {
                                            width: 130,
                                        },
                                        content: {
                                            width: 130,
                                        },
                                    },
                                },
                            },
                            {
                                name: "正定县",
                                value: [114.569435, 38.152849],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|正定县板材家具产业集群}{title|正定县化工医药产业集群}{title|正定县机械制造产业集群}{title|正定县装备制造产业集群}",
                                        "{subTitle|（板材家具）}{subTitle|（碳酸氯铵、肝素系列药）}{subTitle|（面粉机械、金刚石工具）}{subTitle|（混合饲料、速冻食品）}",
                                        "{content|生产企业700个}{content|生产企业76个}{content|生产企业55个}{content|生产企业144个}",
                                        "{content|配套企业1000个}{content|配套企业36个}{content|配套企业32个}{content|配套企业78个}",
                                        "{content|从业人员37000人}{content|从业人员3200人}{content|从业人员9960人}{content|从业人员14000人}",
                                        "{content|营业收入200000万元}{content|营业收入390000万元}{content|营业收入402000万元}{content|营业收入580000万元}",
                                        "{content|上缴税金1400万元}{content|上缴税金14800万元}{content|上缴税金9500万元}{content|上缴税金8900万元}",
                                        "{content|国内市场占有率1%}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 150,
                                        },
                                        subTitle: {
                                            width: 150,
                                        },
                                        content: {
                                            width: 150,
                                        },
                                    },
                                },
                            },
                            {
                                name: "赵县",
                                value: [114.780294, 37.75889],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|赵县淀粉产业集群}{title|赵县纺织产业集群}",
                                        "{subTitle|（淀粉、糖）}{subTitle|（纱、布）}",
                                        "{content|生产企业21个}{content|生产企业248个}",
                                        "{content|配套企业21个}{content|配套企业44个}",
                                        "{content|从业人员580人}{content|从业人员20342人}",
                                        "{content|营业收入72770万元}{content|营业收入874907万元}",
                                        "{content|上缴税金2893万元}{content|上缴税金6519万元}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 130,
                                        },
                                        subTitle: {
                                            width: 130,
                                        },
                                        content: {
                                            width: 130,
                                        },
                                    },
                                },
                            },
                            {
                                name: "无极县",
                                value: [114.901291, 38.137934],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|无极县皮革产业集群}{title|无极县装饰材料产业集群}",
                                        "{subTitle|（成品皮革、箱包皮、皮革加工）}{subTitle|（装饰材料、板材）}",
                                        "{content|生产企业1017个}{content|生产企业996个}",
                                        "{content|配套企业49个}{content|配套企业48个}",
                                        "{content|从业人员101650人}{content|从业人员50796人}",
                                        "{content|营业收入2944300万元}{content|营业收入375200万元}",
                                        "{content|上缴税金29230万元}{content|上缴税金2490万元}",
                                        "{content|国内市场占有率15%}{content|国内市场占有率23%}",

                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 130,
                                        },
                                        subTitle: {
                                            width: 130,
                                        },
                                        content: {
                                            width: 130,
                                        },
                                    },
                                },
                            },
                            {
                                name: "深泽县",
                                value: [115.206828, 38.191132],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|深泽县日化产业集群}",
                                        "{subTitle|（透明皂）}",
                                        "{content|生产企业22个}",
                                        "{content|配套企业13个}",
                                        "{content|从业人员2558人}",
                                        "{content|营业收入220251万元}",
                                        "{content|上缴税金2285万元}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 130,
                                        },
                                        subTitle: {
                                            width: 130,
                                        },
                                        content: {
                                            width: 130,
                                        },
                                    },
                                },
                            },
                            {
                                name: "新乐市",
                                value: [114.693985, 38.351781],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{title|新乐市板材产业集群}{title|新乐市电热毯产业集群}{padding_fill_s|}{title|新乐市石材雕刻产业集群}{padding_fill_m|}{title|新乐市循环化工产业集群}",
                                        "{subTitle|（胶合板）}{subTitle|（电热毯）}{padding_fill_s|}{subTitle|（雕塑工艺品）}{padding_fill_m|}{subTitle|（化肥、甲醇、甲醛、纤维素等）}",
                                        "{content|生产企业40个}{content|生产企业38个}{padding_fill_s|}{content|生产企业64个}{padding_fill_m|}{content|生产企业58个}",
                                        "{content|配套企业112个}{content|配套企业38个}{padding_fill_s|}{content|配套企业72个}{padding_fill_m|}{content|配套企业23个}",
                                        "{content|从业人员6958人}{{content|从业人员8167人}{padding_fill_s|}{content|从业人员2494人}{padding_fill_m|}{content|从业人员10700人}",
                                        "{content|营业收入417053万元}{content|营业收入607410万元}{padding_fill_s|}{content|营业收入487616万元}{padding_fill_m|}{content|营业收入1430496万元}",
                                        "{content|上缴税金8083万元}{content|上缴税金12533万元}{padding_fill_s|}{content|上缴税金9395万元}{padding_fill_m|}{content|上缴税金5256万元}",
                                        "{content|}{content|}{padding_fill_s|}{content|}{padding_fill_m|}{content|国内市场占有率1%}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 130,
                                        },
                                        subTitle: {
                                            width: 130,
                                        },
                                        content: {
                                            width: 130,
                                        },
                                        padding_fill_s: {
                                            width: 16,
                                        },
                                        padding_fill_m: {
                                            width: 26,
                                        },
                                    },
                                },
                            },
                            {
                                name: "栾城区",
                                value: [114.652277, 37.90741],
                                label: {
                                    show: true,
                                    formatter: [
                                        // "{padding_fill|}{title|}{title|}{title|}{title|}",
                                        // "{padding_fill|}{subTitle|}{subTitle|}{subTitle|}{subTitle|}",
                                        // "{padding_fill|}{content|}{content|}{content|}{content|}",
                                        // "{padding_fill|}{content|}{content|}{content|}{content|}",
                                        // "{padding_fill|}{content|}{content|}{content|}{content|}",
                                        // "{padding_fill|}{content|}{content|}{content|}{content|}",
                                        // "{padding_fill|}{content|}{content|}{content|}{content|}",
                                        // "{content|}",
                                        "{title|栾城区南高产业集群}{title|栾城区生物医药产业集群}{padding_fill|}{title|栾城区食品产业集群}{title|栾城区鞋业产业集群}{title|栾城区装备制造产业集群}{padding_fill|}{title|栾城区服装产业集群}",
                                        "{subTitle|（纱、胚布）}{subTitle|（生物医药）}{padding_fill|}{subTitle|（食品）}{subTitle|（硫化布鞋）}{subTitle|（装备制造）}{padding_fill|}{subTitle|（服装）}",
                                        "{content|生产企业98个}{content|生产企业24个}{padding_fill|}{content|生产企业25个}{content|生产企业61个}{content|配套企业40个}{padding_fill|}{content|生产企业40个}",
                                        "{content|配套企业25个}{content|配套企业21个}{padding_fill|}{content|生产企业39个}{content|生产企业25个}{content|生产企业35个}{padding_fill|}{content|从业人员12000人}",
                                        "{content|从业人员11000人}{content|从业人员10301人}{padding_fill|}{content|从业人员4126人}{content|从业人员5468人}{padding_fill|}{content|从业人员10248人}",
                                        "{content|营业收入245000万元}{content|营业收入401800万元}{padding_fill|}{content|营业收入362100万元}{content|营业收入128880万元}{content|营业收入1017200万元}{padding_fill|}{content|营业收入87020万元}",
                                        "{content|上缴税金2200万元}{content|上缴税金18540万元}{padding_fill|}{content|上缴税金7890万元}{content|上缴税金2015万元}{content|上缴税金27520万元}{padding_fill|}{content|上缴税金950万元}",
                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 130,
                                        },
                                        padding_fill: {
                                            width: 30,
                                        },
                                        subTitle: {
                                            width: 130,
                                        },
                                        content: {
                                            width: 130,
                                        },
                                    },
                                },
                            },
                            {
                                name: "藁城区",
                                value: [114.851081, 38.026225],
                                label: {
                                    show: true,
                                    formatter: [
                                        "{padding_fill|}{title|}{title|}{padding_fill|}{title|藁城区纺织服装产业集群}",
                                        "{padding_fill|}{subTitle|}{subTitle|}{padding_fill|}{subTitle|（棉纱、棉布）}",
                                        "{padding_fill|}{content|}{content|}{padding_fill|}{content|生产企业160个}",
                                        "{padding_fill|}{content|}{content|}{padding_fill|}{content|生产企业50个}",
                                        "{padding_fill|}{content|}{content|}{padding_fill|}{content|从业人员5400人}",
                                        "{padding_fill|}{content|}{content|}{padding_fill|}{content|营业收入171000万元}",
                                        "{padding_fill|}{content|}{content|}{padding_fill|}{content|上缴税金6210万元}",
                                        "{content|}",
                                        "{title|藁城区宫灯产业集群}{title|藁城区医药产业集群}{title|藁城区杂粮产业集群}{title|藁城区装备制造产业集群}",
                                        "{subTitle|（宫灯、彩旗）}{subTitle|（生物医药）}{subTitle|（杂粮产品）}{subTitle|（化工产业）}",
                                        "{content|生产企业800个}{content|生产企业136个}{content|生产企业209个}{content|生产企业61个}",
                                        "{content|配套企业110个}{content|配套企业0个}{content|生产企业65个}{content|生产企业25个}",
                                        "{content|从业人员27000人}{content|从业人员27022人}{content|从业人员3773人}{content|从业人员24725人}",
                                        "{content|营业收入219600万元}{content|营业收入4242333万元}{content|营业收入103265万元}{content|营业收入398825万元}",
                                        "{content|上缴税金17100万元}{content|上缴税金175237万元}{content|上缴税金5440万元}{content|上缴税金13975万元}",
                                        "{content|}",
                                        "{padding_fill|}{padding_fill|}{title|}{title|藁城区板材产业集群}{title|藁城区变压器及配件产业集群}",
                                        "{padding_fill|}{padding_fill|}{subTitle|}{subTitle|（中、高密度板）}{subTitle|（变压器、铁芯）}",
                                        "{padding_fill|}{padding_fill|}{content|}{content|生产企业35个}{content|生产企业90个}",
                                        "{padding_fill|}{padding_fill|}{content|}{content|配套企业165个}{content|配套企业165个}",
                                        "{padding_fill|}{padding_fill|}{content|}{content|从业人员5380人}{content|从业人员8460人}",
                                        "{padding_fill|}{padding_fill|}{content|}{content|营业收入76206万元}{content|营业收入282000万元}",
                                        "{padding_fill|}{padding_fill|}{content|}{content|上缴税金1760万元}{content|上缴税金11750万元}",
                                        "{content|}",

                                    ].join("\n"),
                                    rich: {
                                        title: {
                                            width: 130,
                                        },
                                        padding_fill: {
                                            width: 60,
                                        },
                                        subTitle: {
                                            width: 130,
                                        },
                                        content: {
                                            width: 130,
                                        },
                                    },
                                },
                            },
                        ],
                        showEffectOn: "render",
                        rippleEffect: {
                            brushType: "stroke",
                        },
                        hoverAnimation: true,
                        itemStyle: {
                            normal: {
                                color: "#416f79",
                                shadowBlur: 10,
                                shadowColor: "#74D0E5",
                                opacity:0.9
                            },
                        },
                        zlevel: 1,
                    },
                ],
            });
            this.myChart.setOption(optionXyMap01);
            let that = this;
            // 第四张图延时显示。。。
            this.myChart.on("timelinechanged", function (params) {
                that.picShow = params.currentIndex;
            });
            this.myChart.on("click", function (params) {
                if (params.componentSubType === "effectScatter") {
                    that.title = params.name;
                }
            });
        },
        methods: {},
    };
</script>
<style lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
    }

    .bg {
        height: 100%;
        width: 100%;
    }

    .center_xy {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .echarts {
        height: 100%;
        width: 100%;
        position: relative;
        background-size: 100% 100%;
    }

    .city_box1 {
        right: 374px;
        top: 303px;
        position: absolute;
    }

    .fl {
        float: left
    }


    .clear_fix:before, .clear_fix:after {
        display: table;
        content: ""
    }

    .clear_fix:after {
        clear: both
    }


    .middle-map .map-wrap .unit-wan:after, .middle-map .map-wrap .unit-wanyuan:after, .middle-map .map-wrap .unit-number:after, .middle-map .map-wrap .unit-pc:after, .middle-map .map-wrap .unit-type:after {
        display: inline-block;
        margin-left: 4%;
        font-size: 12px;
        color: #fff
    }

    .middle-map .map-wrap .unit-wan:after {
        content: '万'
    }

    .middle-map .map-wrap .unit-wanyuan:after {
        content: '万元'
    }

    .middle-map .map-wrap .unit-number:after {
        content: '个'
    }

    .middle-map .map-wrap .unit-type:after {
        content: '人'
    }

    .middle-map .map-wrap .unit-pc:after {
        content: '批次'
    }


    .middle-map {
        position: absolute;
        width: 70%;
        margin: 0 .5%;
        top: 130px;
    }

    .middle-map h3 {
        height: 9%
    }

    .middle-map .map-wrap {
        position: relative;
        opacity: .9;
        height: 100%;
        /*border-top: 4px solid #121e52;*/
        /*border-bottom: 4px solid #10144b;*/
        /*background: rgba(18, 22, 64, 0.5);*/
        /*background-size: 100% 100%*/
    }

    .middle-map .map-wrap h3 {
        position: absolute;
        top: 4%;
        left: 50%;
        width: 100%;
        padding: 0 3%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0)
    }

    .middle-map .map-wrap h3 dl {
        width: 20%;
        padding: 0.4% 0.2% 0 0.4%;
        border-left: 2px solid #00bbec
    }

    .middle-map .map-wrap h3 dl dt {
        font-size: .78125em;
        color: #00bbec;
        margin-bottom: 5%
    }

    .middle-map .map-wrap .title-map {
        position: absolute;
        left: 50%;
        top: 20%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        font-size: .5625em
    }

    .middle-map .map-wrap h3 dl dd {
        font-family: DigifaceWide;
        font-size: 1.5em;
        color: #f69568
    }

    @font-face {
        font-family: DigifaceWide;
        src: url("../assets/font/digifaw.ttf")
    }

    /*.pic4-title-box{*/
    /*    position: absolute;*/
    /*    top: 100px;*/
    /*    left: 0px;*/
    /*    width: 100%;*/
    /*    background-color: red;*/
    /*}*/

    .pic4-title {
        position: absolute;
        top: 80px;
        left: 10px;
        width: 400px;
        height: 20px;
        background: url(../assets/image/title.png) no-repeat center;
        background-size: 100%;
        color: #fff;
        font-size: 0.9rem;
        text-align: center;
        display: table;
    }

    .pic4-title span {
        display: table-cell;
        vertical-align: middle;
        color:#fff;
        font-size: 1.2rem;
        font-weight: bolder;
    }

</style>